import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PrimeNGModule } from './prime-ng/prime-ng.module';
import { ListaComponentesComponent } from './layouts/lista-componentes/lista-componentes.component';
import { HeaderComponent } from './shared/header/header.component';
import { DesplegablesResumenComponent } from './shared/desplegables-resumen/desplegables-resumen.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MenuComponent } from './shared/menu/menu.component';
import { MenuDesplegableComponent } from './shared/menu-desplegable/menu-desplegable.component';
import { KlapComponent } from './layouts/klap/klap.component';
import { KlapErrorComponent } from './layouts/klap-error/klap-error.component';
import { KlapTartejasComponent } from './layouts/klap-tartejas/klap-tartejas.component';
import { KlapTartejasDatosComponent } from './layouts/klap-tartejas-datos/klap-tartejas-datos.component';
import { StepsComponent } from './shared/steps/steps.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { RouterModule } from '@angular/router';
import { ExitoComponent } from './layouts/exito/exito.component';
import { ErrorComponent } from './layouts/error/error.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { InicioCotizadorComponent } from './layouts/inicio-cotizador/inicio-cotizador.component';
import { IntencionComponent } from './layouts/intencion/intencion.component';
import { ExitoTransaccionComponent } from './layouts/exito-transaccion/exito-transaccion.component';
import { EmisionDirectaComponent } from './layouts/emision-directa/emision-directa.component';
import { ExitoEmisionDirectaComponent } from './layouts/exito-emision-directa/exito-emision-directa.component';
import { InicioContinuarCotizadorComponent } from './layouts/inicio-continuar-cotizador/inicio-continuar-cotizador.component';
import { ModalGpsComponent } from './layouts/modal-gps/modal-gps.component';
import { VinetaEstadosGeneralesComponent } from './shared/vineta-estados-generales/vineta-estados-generales.component';

@NgModule({
  declarations: [
    ListaComponentesComponent,
    HeaderComponent,
    DesplegablesResumenComponent,
    FooterComponent,
    MenuComponent,
    MenuDesplegableComponent,
    KlapComponent,
    KlapErrorComponent,
    KlapTartejasComponent,
    KlapTartejasDatosComponent,
    StepsComponent,
    LoadingComponent,
    ExitoComponent,
    ErrorComponent,
    FileUploadComponent,
    DialogComponent,
    InicioCotizadorComponent,
    IntencionComponent,
    ExitoTransaccionComponent,
    EmisionDirectaComponent,
    ExitoEmisionDirectaComponent,
    InicioContinuarCotizadorComponent,
    ModalGpsComponent,
    VinetaEstadosGeneralesComponent,
  ],
  imports: [
    PrimeNGModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    ListaComponentesComponent,
    HeaderComponent,
    DesplegablesResumenComponent,
    FooterComponent,
    MenuComponent,
    MenuDesplegableComponent,
    KlapComponent,
    KlapErrorComponent,
    KlapTartejasComponent,
    KlapTartejasDatosComponent,
    StepsComponent,
    LoadingComponent,
    FileUploadComponent,
    VinetaEstadosGeneralesComponent,
  ],
})
export class ComponentsModule {}
