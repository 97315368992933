import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_COTIZADOR } from '@utils/url.util';
@Injectable({
  providedIn: 'root',
})
export class CotizacionService {
  constructor(private httpClient: HttpClient) {}

  async generarCotizacion(data: any): Promise<any> {
    return this.httpClient
      .post(URL_COTIZADOR + '/Cotizacion', data)
      .toPromise();
  }

  async subirFactura(
    archivo: any,
    tipoArchivo: number,
    cotizacionId: string
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', archivo);
    const url =
      URL_COTIZADOR +
      '/Upload?tipoArchivo=' +
      tipoArchivo +
      '&cotizacionId=' +
      cotizacionId;

    let resultado = this.httpClient.put(url, formData).toPromise();

    return resultado;
  }

  async generarSolicitudInspeccion(cotizacionId: string): Promise<any> {
    return this.httpClient
      .post(URL_COTIZADOR + '\\generar-solicitud-inspeccion', {
        IdCotizacion: cotizacionId,
      })
      .toPromise();
  }

  async generarDocumentoCotizacion(
    cotizacionId: string,
    descuento: number,
    deducibles: any[],
    nombreCorredor: string,
    rutCorredor: string
  ): Promise<any> {
    const body = {
      CotizacionId: cotizacionId,
      DeduciblesId: deducibles,
      Descuento: descuento,
      NombreCorredor: nombreCorredor,
      RutCorredor: rutCorredor,
    };
    return this.httpClient
      .post(URL_COTIZADOR + '\\GenerarDocumentocotizacion', body, {
        responseType: 'blob',
      })
      .toPromise();
  }

  async obtenerDocumentoCotizacion(
    cotizacionId: string,
    tipoDocumento: number
  ): Promise<any> {
    const url = '\\ObtenerDocumentoCotizacion';
    let params = new HttpParams()
      .set('id', cotizacionId)
      .set('ta', tipoDocumento.toString());

    return this.httpClient
      .get(URL_COTIZADOR + url, { responseType: 'blob', params })
      .toPromise();
  }

  async obtenerCotizacion(cotizacionId: string): Promise<any> {
    const url = '\\ObtenerDatosContinuarCotizacion';
    let params = new HttpParams().set('id', cotizacionId);
    return this.httpClient.get(URL_COTIZADOR + url, { params }).toPromise();
  }

  async consultarEstadoInspeccion(numeroInspeccion: string) {
    const url = '\\consultar-estado-inspeccion';
    let params = new HttpParams().set('numeroInspeccion', numeroInspeccion);
    return this.httpClient.get(URL_COTIZADOR + url, { params }).toPromise();
  }

  async obtenerScriptEjecutivo(
    cotizacionId: string
  ): Promise<{ scriptEjecutivo: string | null }> {
    const url = '\\ObtenerScriptEjecutivo';
    let params = new HttpParams().set('cotizacionId', cotizacionId);
    return this.httpClient
      .get<{ scriptEjecutivo: string | null }>(URL_COTIZADOR + url, { params })
      .toPromise();
  }

  async obtenerNumeroInspeccion(cotizacionId: string) {
    const url = '\\obtener-numero-inspeccion';
    let params = new HttpParams().set('cotizacionId', cotizacionId);
    return this.httpClient.get(URL_COTIZADOR + url, { params }).toPromise();
  }
}
