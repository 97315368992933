import { DesplegableResumen } from '@components/shared/desplegables-resumen/desplegable-resumen.interface';
import { CotizacionModel } from '@model/cotizacion-model';
import { Formulario } from './formulario';

export interface SeguroStore {
  urlBase: string;
  resumen: DesplegableResumen;
  formulario: Formulario;
  datosTarificacion: DatosTarificacion;
  datosConvenio: DatosConvenio;
  datosCotizacion: CotizacionModel;
  datosPin: DatosPin;
  continuaCotizacion: boolean;
  token: string;
  tieneFactura: boolean;
  googleTagManagerHead: string;
  googleTagManagerBody: string;
  mostrarMandato: boolean;
  validarChasisSinPatronesRepetidos: boolean;
  validarMotorSinPatronesRepetidos: boolean;
  productoAdicional: ProductoAdicional | null;
  tipoFlujo: 'NORMAL' | 'PT+RC';
}

export class ProductoAdicional {
  public moneda: string = '';
  public productos: Producto[] = [];
  public tasaCambio: number = 0;
  public tituloPrincipal: string = '';
}

export class Producto {
  public titulo: string = '';
  public descripcion: string = '';
  public valorCuotaUf: number = 0;
  public valorPesos: number = 0;
  public idConProductoEspecial: number = 0;
}

export class DatosTarificacion {
  public numeroCotizacion: any;
  public resumen: any[] = [];
  public planes: any[] = [];
  public tarifas: any[] = [];
  public tarificacionId: any;
  public coberturas: any[] = [];
  public deducibleSeleccionado: any;
  public tarifa: any;
  public deducibles: any[] = [];
  public retarificar: boolean = false;
  public tipoCuota: string = '';
  public valorUfDia: number = 0;
}

export class DatosConvenio {
  public nombreConvenio: string = '';
  public id: number = 0;
  public descuentos: string[] = [];
  public cantidadCuotas: number = 0;
  public pmaIds: string[] = [];
  public sucursal: string = '';
  public nombreCorredor: string = '';
  public rutCorredor: string = '';
  public diasVigencia: number = 0;
  public enableRecaptchaEnterprise: boolean = false;
  public reCaptchaEnterprisePublicKey = '';
  public tagManagerHead?: string;
  public tagManagerBody?: string;
  public recaptchaKey?: string;
  public habilitaPines?: boolean;
  public ramos?: string[] = [];
  public numeroCuota0?: number;
  public cuotaSimbolica?: number;
  public mostrarMandato?: boolean;
  public validarChasisSinPatronesRepetidos?: boolean;
  public validarMotorSinPatronesRepetidos?: boolean;
  public productoAdicional?: ProductoAdicional | null;
  public tipoFlujo: 'NORMAL' | 'PT+RC' = 'NORMAL';
}

export class DatosPin {
  public nroPin: string = '';
  public descuento: number = 0;
}
